import React from "react"
import Service from "../Service"
import companiesStyles from "./services.module.css"
import riskManagement from "../../images/risk_management.jpg"
import operationsSupport from "../../images/operation-support.jpg"
import valuation from "../../images/valuation.jpg"

export const Services = () => (
  <div id="services" className={companiesStyles.container}>
    <h4 className={companiesStyles.title}>Our Services</h4>
    <div className={companiesStyles.companies}>
      <Service
        caption="
          Setting strategic direction for mining operations and exploration
          license tenements with focus on sustainable organic and inorganic growth;
          identifying opportunities and risks, and taking Enterprise Risk Management initiatives
        "
        image_link={riskManagement}
        logo_class={companiesStyles.keepsLogo}
        link="/#services-detail"
        title="STRATEGY & ENTERPRISE RISK MANAGEMENT"
      />
      <Service
        caption="Transforming difficult operations into profit yielding assets by unlocking hidden improvements"
        image_link={operationsSupport}
        logo_class={companiesStyles.coveLogo}
        link="/#services-detail"
        title="OPERATIONS SUPPORT"
      />
      <Service
        caption="Evaluation of potential mineral assets or investments and marketing same"
        image_link={valuation}
        logo_class={companiesStyles.evensLogo}
        link="/#services-detail"
        title="ASSET VALUATION & MARKETING"
      />
    </div>
  </div>
)

export default Services