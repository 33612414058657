import React from "react"
import SEO from "./seo"
import Header from "./Header/header"
import Jumbotron from "./Jumbotron"
import About from "./About"
import Services from "./Services"
import "./layout.module.css"


const Layout = () => {
  return (
    <>
      <SEO
        title="Mineral Resources Development Consultants, Ghana"
        description="Mineral Resources Development Consultants, Ghana"
      />
      <Header siteTitle="siteTitle={data.site.siteMetadata.title}"/>
      <Jumbotron description="Nulla consectetur, nibh id scelerisque viverra, nisi arcu commodo turpis, quis scelerisque nunc libero a massa." />
      <About />
      <Services/>
    </>
  )
}

export default Layout