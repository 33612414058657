import React from "react"
import Helmet from "../../images/mining-helmet.jpg"
import mrdStyles from "./about.module.css"

const About = () => (
  <div>
    <div className={mrdStyles.mrd}>
      <div>
        <div className={mrdStyles.badge}>
          <img src={Helmet} alt="Helmet" width="50" height="40"/>
        </div>
        <h2 className={mrdStyles.mrdDesc}>
          Morbi in ultricies elit. Curabitur laoreet, quam vitae egestas tempor, dui metus mattis sem, vitae vulputate lorem nibh vel ante.
          Fusce sodales orci quis eros molestie ornare. Nam vel mauris purus.
          Integer erat lacus, finibus at rutrum at, pulvinar et turpis. Cras vulputate, nunc eget posuere consequat, ex sem dignissim ligula
        </h2>
      </div>
    </div>
    <div>
      <div className={mrdStyles.featuresContainer} id="about-us">
        <div className={mrdStyles.feature}>
          <h5>LOREM IPSUM</h5>
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas porttitor condimentum ligula eu egestas.
            Fusce convallis quis orci eu pellentesque. Quisque nec augue eget odio venenatis aliquet in sit amet urna.
            Quisque ante nulla, tristique in fermentum quis, egestas non velit.
          </p>
        </div>
        <div className={mrdStyles.feature}>
          <h5>FOOZ BARZ</h5>
          <p>
            Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.
            Vestibulum nibh ipsum, facilisis ac elit sed, dictum semper leo.
            Quisque ante nulla, tristique in fermentum quis, egestas non velit.
            Praesent varius sollicitudin massa. Donec eu velit dui. Cras eleifend tempor neque at eleifend.
          </p>
        </div>
        <div className={mrdStyles.feature}>
          <h5>PRASAENT ET LIBERO</h5>
          <p>
            fermentum, commodo eros quis, luctus risus. Nunc bibendum in odio ac malesuada. Duis sagittis hendrerit felis, et vulputate lorem. Morbi vel ex nisi.
            Maecenas condimentum luctus nulla non blandit. Suspendisse malesuada, quam ut aliquet hendrerit, lorem leo aliquam
            libero, a cursus arcu sem ac turpis.
          </p>
        </div>
      </div>
    </div>
  </div>
)

export default About