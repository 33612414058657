import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import companiesStyles from "../Services/services.module.css"

const Service = props => (
  <div className={companiesStyles.company}>
    <Link
      to={props.link}
      className={props.text ? companiesStyles.disabled : ""}
    >
      <div className={`${companiesStyles.companyLogo} ${props.logo_class}`}>
        <div className={companiesStyles.comingSoon}>{props.text}</div>
      </div>
      <p className={companiesStyles.titleText}>{props.title}</p>
      <p className={companiesStyles.intro}>
        {props.caption}
        <br />
      </p>
    </Link>
  </div>
)

Service.propTypes = {
  logo_class: PropTypes.string.isRequired,
  image_link: PropTypes.string,
  link: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  caption: PropTypes.string.isRequired
}

Service.defaultProps = {
  image_link: null,
  link: "/",
  text: ""
}

export default Service