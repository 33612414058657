import React from "react"
import { Link } from "gatsby"
import headerStyles from "./header.module.css"
import Hamburger from "../../images/hamburg.svg"
import Close from "../../images/close.svg"
import MobileNavigation from "../MobileNavigation"

class Header extends React.Component {
  constructor(props) {
    super(props)
    this.state = { navCollapsed: false }
    this.toggleNav = this.toggleNav.bind(this)
  }

  toggleNav() {
    this.setState(state => ({ navCollapsed: !state.navCollapsed }))
  }

  render() {
    const { navCollapsed } = this.state
    return (
      <div>
        <header className={navCollapsed ? headerStyles.collapse : null}>
          <Link to="/" className={headerStyles.title}>
            MRD CONSULTANTS
          </Link>
          <nav>
            <ul className={headerStyles.navLinks}>
              <li>
                <Link to="/#about-us">About us</Link>
              </li>
              <li>
                <Link to="/#services">Services</Link>
              </li>
              <li>
                <Link to="/#contact-us">Contact us</Link>
              </li>
            </ul>
            <button
              type="button"
              aria-expanded="false"
              className={`${navCollapsed ? headerStyles.menu : null} ${headerStyles.navToggleIcon}`}
              onClick={this.toggleNav}
            >
              <img className={headerStyles.hamburger} src={Hamburger} alt="hamburger" />
              <img className={headerStyles.closeIcon} src={Close} alt="close" />
            </button>
          </nav>
        </header>
        {navCollapsed && <MobileNavigation toggleNav={this.toggleNav} />}
      </div>
    )
  }
}

Header.defaultProps = {
  siteTitle: ""
}

export default Header