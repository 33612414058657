import PropTypes from "prop-types"
import React from "react"
import jumbotronStyles from "./jumbotron.module.css"

const Jumbotron = ({ description }) => (
  <div className={jumbotronStyles.wrapper}>
    <div className={jumbotronStyles.jumbotron}>
      <h1>{description}</h1>
    </div>
  </div>
)

Jumbotron.propTypes = {
  description: PropTypes.string
}

Jumbotron.defaultProps = {
  description: ""
}
export default Jumbotron