import React from "react"
import PropTypes from "prop-types"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons"
import footerStyles from "./footer.css"

const Footer = ({ style }) => (
  <div className={`${footerStyles.container} ${style} `} id="contact-us">
    <div>
      <a href="mailto:press@thirtymadison.com" className={footerStyles.link} target="_top" rel="noopener noreferrer">
        info@mrd-consultants.com
      </a>
    </div>
    <div className={footerStyles.socialIcons}>
      <a href="https://www.linkedin.com/company/thirty-madison-inc./" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedinIn} />
      </a>
    </div>
  </div>
)

Footer.propTypes = {
  style: PropTypes.string
}

Footer.defaultProps = {
  style: ""
}

export default Footer